const SVGHero = () => (
  <svg
    width="595"
    height="516"
    viewBox="0 0 595 516"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of circles of various sizes, bar lines and a triangle</title>
    <g opacity="0.5">
      <g filter="url(#filter0_i_78_1733)">
        <circle cx="336.174" cy="258" r="258" fill="#FEDECD" />
      </g>
      <circle cx="336.174" cy="258" r="198.879" fill="url(#paint0_linear_78_1733)" />
      <circle
        cx="336.174"
        cy="257.999"
        r="142.521"
        fill="url(#paint1_linear_78_1733)"
        fillOpacity="0.9"
      />
    </g>
    <rect
      x="304.085"
      y="133"
      width="18.9668"
      height="303.755"
      transform="rotate(90 304.085 133)"
      fill="url(#paint2_linear_78_1733)"
    />
    <rect
      x="304.085"
      y="229.76"
      width="18.9668"
      height="303.755"
      transform="rotate(90 304.085 229.76)"
      fill="url(#paint3_linear_78_1733)"
    />
    <rect
      x="304.085"
      y="197.507"
      width="18.9668"
      height="303.755"
      transform="rotate(90 304.085 197.507)"
      fill="url(#paint4_linear_78_1733)"
    />
    <rect
      x="304.085"
      y="294.267"
      width="18.9668"
      height="303.755"
      transform="rotate(90 304.085 294.267)"
      fill="url(#paint5_linear_78_1733)"
    />
    <rect
      x="304.085"
      y="165.254"
      width="18.9668"
      height="303.755"
      transform="rotate(90 304.085 165.254)"
      fill="url(#paint6_linear_78_1733)"
    />
    <rect
      x="304.085"
      y="262.014"
      width="18.9668"
      height="303.755"
      transform="rotate(90 304.085 262.014)"
      fill="url(#paint7_linear_78_1733)"
    />
    <rect
      x="255.464"
      y="177.29"
      width="161.419"
      height="161.419"
      rx="80.7095"
      fill="url(#paint8_linear_78_1733)"
    />
    <path
      d="M336.175 256C367.943 302.742 420.466 399.477 424.837 409.513L513.654 270.631L336.175 256Z"
      fill="url(#paint9_linear_78_1733)"
    />
    <defs>
      <filter
        id="filter0_i_78_1733"
        x="78.1736"
        y="0"
        width="516"
        height="519.167"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.16709" />
        <feGaussianBlur stdDeviation="1.58354" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.901961 0 0 0 0 0.4 0 0 0 0 0.164706 0 0 0 0.22 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_78_1733" />
      </filter>
      <linearGradient
        id="paint0_linear_78_1733"
        x1="336.174"
        y1="59.1206"
        x2="336.174"
        y2="456.879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9D68" />
        <stop offset="1" stopColor="#FECEB3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_78_1733"
        x1="336.174"
        y1="115.478"
        x2="336.174"
        y2="400.521"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC5C03" />
        <stop offset="1" stopColor="#FFEFE6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_78_1733"
        x1="313.569"
        y1="133"
        x2="313.569"
        y2="436.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stopColor="#808BF3" />
        <stop offset="1" stopColor="#808BF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_78_1733"
        x1="313.569"
        y1="229.76"
        x2="313.569"
        y2="533.515"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stopColor="#808BF3" />
        <stop offset="1" stopColor="#808BF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_78_1733"
        x1="313.569"
        y1="197.507"
        x2="313.569"
        y2="501.261"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stopColor="#808BF3" />
        <stop offset="1" stopColor="#808BF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_78_1733"
        x1="313.569"
        y1="294.267"
        x2="313.569"
        y2="598.022"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stopColor="#808BF3" />
        <stop offset="1" stopColor="#808BF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_78_1733"
        x1="313.569"
        y1="165.254"
        x2="313.569"
        y2="469.009"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stopColor="#808BF3" />
        <stop offset="1" stopColor="#808BF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_78_1733"
        x1="313.569"
        y1="262.014"
        x2="313.569"
        y2="565.769"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stopColor="#808BF3" />
        <stop offset="1" stopColor="#808BF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_78_1733"
        x1="302.148"
        y1="298.788"
        x2="432.144"
        y2="214.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1221A9" />
        <stop offset="1" stopColor="#D9DDFF" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_78_1733"
        x1="350.15"
        y1="240.639"
        x2="427.562"
        y2="367.079"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
