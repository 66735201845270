import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { ImageBlock } from 'components/ImageBlock';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';
import { BlogLink, SignupLink } from 'components/Links';
import { ContentBlock } from 'components/ContentBlock';
import { StatsBlock } from 'components/StatsBlock';
import { PricingBlock } from 'components/PricingBlock';
import { TrustPilot } from 'components/TrustPilot';

import videoThumbnail from 'images/shared-page-assets/youtube-thumbnails/track-your-stocks.png';
import HeroImage from 'images/share-registries/hero';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const ShareRegistries: FC<LocalizedPageProps> = () => {
  const { sections, translations } = useContentfulPage();

  return (
    <Layout hideFooterCta>
      <Seo />

      <HeroContainer background="callout">
        <Section>
          <h1>We show what share registries don't</h1>
          <p>
            Share registries such as {translations.shareRegistries} don’t show the full picture of
            your investments. Automatically track your performance, dividends and tax with
            Sharesight today.
          </p>
          <br />
          <SignupLink asButton showArrowIcon>
            Sign up for free
          </SignupLink>
        </Section>
        <SectionImage image={<HeroImage />} boxShadow={false} borderRadius={false} />
      </HeroContainer>

      <Container>
        <SectionVideo videoId="IIgxxIfHOis" thumbnailUrl={videoThumbnail} />
        <Section>
          <h2>See the best portfolio tracker in action</h2>
        </Section>
      </Container>

      {sections && (
        <ContentBlock
          header={sections[0].header}
          textContent={sections[0].textContent}
          image={sections[0].image}
        />
      )}

      <Container>
        <SectionImage
          image={useGatsbyImage({
            name: 'share-registries/history',
            alt: 'See your entire dividend history',
          })}
          boxShadow={false}
        />
        <Section>
          <h2>See your entire dividend history</h2>
          <p>
            Import your historical trades, then sit back and watch as your dividend income is
            automatically tracked – going back up to 20 years.
          </p>
        </Section>
      </Container>

      <Container>
        <Section>
          <h2>View share registry information</h2>
          <p>
            Sharesight keeps track of which share registry each share you hold is registered to, so
            that you don’t have to.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'share-registries/trade-and-adjustments',
            alt: 'View share registry information',
          })}
          borderRadius={false}
        />
      </Container>

      <Container>
        <SectionImage
          image={useGatsbyImage({
            name: 'share-registries/investing-tips',
            alt: "Why share registries aren't enough",
          })}
          boxShadow={false}
          borderRadius={false}
        />
        <Section>
          <h2>Why share registries aren't enough</h2>
          <p>
            Think your share registry is providing all the information you need? Think again. Read
            our detailed guide on what you're missing by relying solely on your registry.
          </p>
          <br />
          <BlogLink
            to="why-share-registries-arent-enough"
            asButton
            appearance="secondary"
            showArrowIcon
          >
            Read share registry article
          </BlogLink>
        </Section>
      </Container>

      <TrustPilot />

      <StatsBlock />

      <ImageBlock variation="registries" background="white" />

      <PricingBlock />

      <Container background="orangeHighlight">
        <Section>
          <h2>Start tracking your performance for free</h2>
          <p>
            Track up to 10 holdings. <br />
            No credit card needed.
          </p>
          <br />
          <SignupLink asButton appearance="primary" showArrowIcon />
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'shared-page-assets/localised/track-performance',
            alt: 'Zero dollar symbol with stock tickers surrounding it',
          })}
          boxShadow={false}
          borderRadius={false}
          span={2}
        />
      </Container>
    </Layout>
  );
};

export default ShareRegistries;
